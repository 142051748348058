import React, { useContext, useState, createContext } from "react";
import { useLocalStorage } from "../../../helpers/useLocalStorage";
import ApiClient from "../../../helpers/ApiClient";
import { message } from "antd";
import moment from "moment";

//TODO - Move to static file
// const defFlightSearchObj = {
//   airTravelType: "oneWay",
//   oneWayType: "1",
//   traceId: "",
//   stopOver: "",
//   departureDateTime: moment(new Date()).format("YYYY-MM-DD"),
//   returnDateTime: "",
//   origin: "",
//   destination: "",
//   adultCount: 1,
//   childCount: 0,
//   infantCount: 0,
//   cabinClass: "Economy",
//   resultsType: "",
//   searchId: "",
// };
const BusContext = createContext();

export function useBusContext() {
  return useContext(BusContext);
}

const BusProvider = (props) => {
  //Hydrating Flight Data from localstorage

  const [busPersist, setBusPersist] = useLocalStorage("busPersist", {
    busSearchObj: {
      from: null,
      to: null,
      departureDate: new Date(),
    },
    selectedBusData: [],
  });
  const [busPassengerData, setBusPassengerData] = useState([]);
  const [busBookingResp, setBusBookingResp] = useState([]);
  const [busSearchResultObj, setBusSearchResultObj] = useState({});
  const [busSearchObj, setBusObj] = useState({});
  const[sessiontime,setsessiontime]=useState(null);
  const[sessiontimeout,setsessiontimeout]=useState(null);
  const [otherData, setOtherData] = useState({
    promoData: { status: false, Discount: 0 },
    ConvFee: { status: false, amount: 0 },
    //insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
    //selectedInsuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
    redeemAmount: { CouponAmt: 0, status: false },
  });
  const[CFRError,setCFRError]=useState({promomsg:false,Cfrmsg:false});
  const[CFRErrormsg,setCFRErrormsg]=useState({promomsg:null,Cfrmsg:null});
  const changeCFRPromo=(val)=>{
    setCFRError((prev)=>({...prev,Cfrmsg:val}))
    setCFRError((prev)=>({...prev,promomsg:val}))
  }
  const changeCFRPromomsg=(val)=>{
    setCFRErrormsg((prev)=>({...prev,Cfrmsg:val}))
  }
  const AddConvFee = (type) => {
    ApiClient.get("admin/convenienceFee").then((res) => {

      let convobj = { type: 1, amount: 0, id: 0 };
      if(res?.data?.length>0){
      res.data.forEach((i) => {
        if (i.ServiceType == type) {
          convobj.type = i.ConvenienceFeeType;
          convobj.amount = i.Value;
          convobj.id = i.ID;
        }
      });
    }
      setOtherData((prev) => ({
        ...prev,
        ConvFee: convobj,
      }));
    });
  };
  const getsessiontimeout = () => {
    ApiClient.get("admin/get_active_session_timeout/3")
      .then((res) => {
        if (res.status == 200) {
          setsessiontimeout(new Date().getTime() + Number((res.data.sessionTime) * 60 * 1000));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const RemovePromo = () => {
    setOtherData({
      ...otherData,
      promoData: { PromoID: 0, status: false, Discount: 0 },
    });
  };

  const AddPromo = async (text, travelType) => {
    //travelType refer to flight or bus
    // bus=
    //fligght=2

    let res = await ApiClient.get("admin/promo");
    if (res.status != 200) {
      message.error("Invalid Promo Code");
      return { validStatus: false };
    }
    let flag = true;
    res.data.forEach((p) => {
      if (p.Code == text && p.ServiceType == travelType) {
        flag = false;
        p.status = true;
        p.validStatus = true;
        setOtherData({ ...otherData, promoData: p });
      }
    });
    if (flag) {
      message.error("Invalid Promo Code");
      return { validStatus: false };
    }
  };
  const validatePromoCode = (req) => {
    ApiClient.post("admin/validate/promo", req)
      .then((res) => {
        if (res.status === 200) {
          setCFRError((prev)=>({...prev,promomsg:false}))
          setCFRError((prev)=>({...prev,Cfrmsg:false}))
          setOtherData((prev) => ({
            ...prev,
            promoData: res.data,
          }));
        } else {
          if (res?.message) {
            setCFRError((prev)=>({...prev,promomsg:true}))
            setCFRErrormsg((prev)=>({...prev,promomsg:res.message}))
          }//message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => { });
  };
  return (
    <BusContext.Provider
      value={{
        state: {
          busPersist,
          busPassengerData,
          busBookingResp,
          busSearchResultObj,
          busSearchObj,
          sessiontime,
          sessiontimeout,
          otherData,
          CFRError,
          CFRErrormsg
        },
        validatePromoCode: (val) => {
          validatePromoCode(val);
        },
        getsessiontimeout: () => {
          getsessiontimeout();
        },
        getsessiontime: (val) => {
          setsessiontime(val);
        },
        AddPromo: (val, type) => {
          AddPromo(val, type);
        },
        RemovePromo: () => {
          RemovePromo();
        },
        setBusSearchObj: (searchObj) => {
          setBusPersist((prev) => ({
            ...prev,
            busSearchObj: searchObj,
          }));
        },
        updateBusSearchResultObj: (busSearchResp) => {
          setBusSearchResultObj(busSearchResp);
        },
        changeCFRPromo:(val)=>{
          changeCFRPromo(val);
        },
        changeCFRPromomsg:(val)=>{
          changeCFRPromomsg(val)
        },
        updatebusobj: (busobj) => {
          setBusObj(busobj);
        },
        updateSelectedBusData: (selectedbusobj) => {
          setBusPersist((prev) => ({
            ...prev,
            selectedBusData: [selectedbusobj],
          }));
        },
        updateBusPassengerData: (obj) => {
          setBusPassengerData(obj);
        },
        AddConvFee: (v) => {
          AddConvFee(v);
        },
        RemovePromo: () => {
          RemovePromo();
        },

        updateBusBookingData: (respObj) => {
          setBusBookingResp(respObj);
        },
        updateOtherData: (val) => {
          setOtherData(val);
        },
      }}
    >
      {props.children}
    </BusContext.Provider>
  );
};
export default BusProvider;
