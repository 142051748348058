import React, { useState, useEffect } from "react";
import GlobalStatesContext from "./GlobalStatesContext";
import { message } from "antd";
import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import EncHelper from "../../helpers/EncHelper";

// const defBusSearch = {
//   type: "oneWay",
//   from: null,
//   to: null,
//   departureDate: new Date(),
//   returnDate: null,
// };

const defCombinedSearch = {
  tripType: "oneWay",
  classType: "Economy",
  origin: [],
  destination: [],
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: "",
  adultCount: 1,
  childCount: 0,
  infantCount: 0,
};

const GlobalStatesProvider = (props) => {
  const AddPromo = async (text, travelType) => {
    //travelType refer to flight or bus
    // bus=
    //fligght=2

    let res = await ApiClient.get("admin/promo");
    if (res.status != 200) {
      message.error("Invalid Promo Code");
      return { validStatus: false };
    }
    let flag = true;
    res.data.forEach((p) => {
      if (p.Code == text && p.ServiceType == travelType) {
        flag = false;
        p.status = true;
        p.validStatus = true;
        setOtherData({ ...otherData, promoData: p });
      }
    });
    if (flag) {
      message.error("Invalid Promo Code");
      return { validStatus: false };
    }
  };

  const RemovePromo = () => {
    setOtherData({
      ...otherData,
      promoData: { PromoID: 0, status: false, Discount: 0 },
    });
  };

  const AddConvFee = (type) => {
    ApiClient.get("admin/convenienceFee").then((res) => {
     
      let convobj = { type: 1, amount: 0, id: 0 };
      if(res?.data?.length>0){
      res.data.forEach((i) => {
        if (i.ServiceType == type) {
          convobj.type = i.ConvenienceFeeType;
          convobj.amount = i.Value;
          convobj.id = i.ID;
        }
      });
    }
      setOtherData((prev) => ({
        ...prev,
        ConvFee: convobj,
      }));
    });
  };

  const RemovePromoConvFee = () => {
    setOtherData({
      promoData: { status: false, Discount: 0 },
      ConvFee: { status: false, amount: 0 },
      insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
      selectedInsuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
      redeemAmount: { CouponAmt: 0, status: false },
    });
  };
  //Hydrating Bus Data from localstorage
   const busPersistJson = JSON.parse(localStorage.getItem("busPersist")) || {};
   let initBusSearch = busPersistJson.busData;
   let initSelectedBusData = busPersistJson.selectedBusData || [];
   const [busData, setBusData] = useState(initBusSearch);
   const [selectedBusData, setSelectedBusData] = useState(initSelectedBusData);
  const [busPassengerData, setBusPassengerData] = useState([]);
   const [busSearchResultObj, setBusSearchResultObj] = useState({});

  //Hydrating Combined Data from localstorage
  // const combinedPersistJson =
  //   JSON.parse(localStorage.getItem("combinedPersist")) || {};
  // let initCombinedSearch =
  //   combinedPersistJson.combineSearchData || defCombinedSearch;

  const loginCheck = JSON.parse(EncHelper.getItem("login")) || {
    flag: false,
    role: false,
  };
  const [isLogin, setLogin] = useState(loginCheck);
  const [airlineMatrixReset, setAirlineMatrixReset] = useState(false);

  const [otherData, setOtherData] = useState({
    promoData: { PromoID: 0, status: false, Discount: 0 },
    ConvFee: { type: 1, amount: 0 },
    mealData: [],
    baggageData: [],
  });
  const[CFRError,setCFRError]=useState({promomsg:false,Cfrmsg:false});
  const[CFRErrormsg,setCFRErrormsg]=useState({promomsg:null,Cfrmsg:null});
  const changeCFRPromo=(val)=>{
    setCFRError((prev)=>({...prev,Cfrmsg:val}))
    setCFRError((prev)=>({...prev,promomsg:val}))
  }
  const changeCFRPromomsg=(val)=>{
    setCFRErrormsg((prev)=>({...prev,Cfrmsg:val}))
  }
  const validatePromoCode = (req) => {
    ApiClient.post("admin/dynamicvalidate/promo", req)
      .then((res) => {
        if (res.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            promoData: res.data,
          }));
        } else {
          if (res?.message){ 
       // message.error(res?.message)
              setCFRError((prev)=>({...prev,promomsg:true}))
          setCFRErrormsg((prev)=>({...prev,promomsg:res.message}))
        }

          else message.error("Failed", 3);
        }
      })
      .catch((error) => {});
  };

  // const [trainInfo, setTrainInfo] = useState({
  //   depatureStation: "",
  //   arrivalStation: "",
  //   departureDate: new Date(),
  //   submitted: false,
  // });

  // const [trainFilters, setTrainFilters] = useState({
  //   depTimes: [],
  //   arrTimes: [],
  //   depStations: [],
  //   arrStations: [],
  // });

  // const [combineSearchData, setCombineSearchData] =
  //   useState(initCombinedSearch);

  // useEffect(() => {
  //   //Bus Data in localstorage
  //   localStorage.setItem(
  //     "busPersist",
  //     JSON.stringify({
  //       busData: busData,
  //       selectedBusData: selectedBusData,
  //     })
  //   );
  // }, [busData, selectedBusData]);

  // useEffect(() => {
  //   //Bus Data in localstorage
  //   localStorage.setItem(
  //     "combinedPersist",
  //     JSON.stringify({
  //       combineSearchData: combineSearchData,
  //     })
  //   );
  // }, [combineSearchData]);

  return (
    <GlobalStatesContext.Provider
      value={{
        state: {
          busData,
          // trainInfo,
          // trainFilters,
           selectedBusData,
           busPassengerData,
          isLogin,
          // combineSearchData,
           busSearchResultObj,
          otherData,
          airlineMatrixReset,
          CFRError,
          CFRErrormsg,
        },
        ResetAirlineMatrix: (val) => {
          setAirlineMatrixReset(val);
        },
        AddPromo: (val, type) => {
          AddPromo(val, type);
        },
        AddConvFee: (v) => {
          AddConvFee(v);
        },
        updateOtherData: (val) => {
          setOtherData(val);
        },
        UpdateMealData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            mealData: val,
          }));
        },
        UpdateBaggageData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            baggageData: val,
          }));
        },
        RemovePromoConvFee: () => {
          RemovePromoConvFee();
        },
        RemovePromo: () => {
          RemovePromo();
        },

        updateIsLogin: (attribute, value) => {
          let temp = {
            ...isLogin,
            [attribute]: value,
          };
          setLogin((prev) => ({ ...prev, [attribute]: value }));
          EncHelper.setItem("login", JSON.stringify(temp));
        },
         updateBusField: (attribute, value) => {
           setBusData({
             ...busData,
             [attribute]: value,
          });
         },
         updateSelectedBusData: (selectedbusobj, travelType) => {
           if (travelType === "oneWay") {
             setSelectedBusData([selectedbusobj]);
           }
           if (travelType === "roundTrip1") {
             setSelectedBusData([selectedbusobj]);
           } else if (travelType === "roundTrip2" && selectedBusData.length < 2) {
             setSelectedBusData((prev) => [...prev, selectedbusobj]);
           }
         },
         updateBusPassengerData: (obj) => {
           setBusPassengerData(obj);
       },

        // updadateTrainField: (attribute, value) => {
        //   setTrainInfo({
        //     ...trainInfo,
        //     [attribute]: value,
        //   });
        // },
        // updateTrainFilters: (attribute, value) => {
        //   setTrainFilters({
        //     ...trainFilters,
        //     [attribute]: value,
        //   });
        // },
        // updataCombineSearchObj: (attribute, value) => {
        //   setCombineSearchData((prev) => ({ ...prev, [attribute]: value }));
        // },
         updateEmptySelectedBusData: () => {
           setSelectedBusData([]);
         },
         updateBusSearchResultObj: (busSearchResp) => {
           setBusSearchResultObj(busSearchResp);
         },
         resetBusSearchObj: (busSearchObj) => {
           setBusData(busSearchObj);
         },
        validatePromoCode: (val) => {
          validatePromoCode(val);
        },
        changeCFRPromo:(val)=>{
          changeCFRPromo(val);
        },
        changeCFRPromomsg:(val)=>{
          changeCFRPromomsg(val)
        },
      }}
    >
      {props.children}
    </GlobalStatesContext.Provider>
  );
};
export default GlobalStatesProvider;
