import React, { createContext, useState, useContext } from "react";
import { useLocalStorage } from "../../../helpers/useStorage";
import { useSessionStorage } from "../../../helpers/useStorage";
import ApiClient from "../../../helpers/ApiClient";
export const HotelContext = createContext();

export function useHotelContext() {
  return useContext(HotelContext);
}

const HotelProvider = ({ children }) => {
  const [hotelCheckOutData, setHotelCheckOutData] = useSessionStorage(
    "hotelcheckoutdata",
    {}
  );
  const [sessiontimeout, setsessiontimeout] = useState([]);
  const [searchhotelobj, setsearchhotelobj] = useState({});
  const [shortedHotelModalVisible, setShortedHotelModalVisible] = useState(false)
  const [shortedHotelCount, setShortedHotelListCount] = useState(0)
  const [shortedHotelList, setShortedHotelList] = useState([])

  // const [selectedHotel, setSelectedHotel] = useLocalStorage(
  //   "selectedhotel",
  //   {}
  // );
  const getsessiontimeout = () => {
    ApiClient.get("admin/get_active_session_timeout/2")
      .then((res) => {
        if (res.status == 200) {
          setsessiontimeout(new Date().getTime() + Number((res?.data?.sessionTime) * 60 * 1000));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

 
  return (
    <HotelContext.Provider
      value={{
        state: { hotelCheckOutData, searchhotelobj, sessiontimeout, shortedHotelModalVisible, shortedHotelCount,shortedHotelList },
        setHotelCheckOutData,
        searchhotelobj: (val) => {
          setsearchhotelobj(val);
        },
        getsessiontimeout: () => {
          getsessiontimeout();
        },
        setShortedHotelModalVisible: (val) => {

          setShortedHotelModalVisible(val)
        },
        setShortedHotelListCount: (val) => {
          setShortedHotelListCount(val)
        },
        setShortedHotelList: (val) => {
          setShortedHotelList(val)
        }
      }}
    >
      {children}
    </HotelContext.Provider>
  );
};
export default HotelProvider;
