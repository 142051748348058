import React, { createContext, useEffect, useState, useContext } from "react";
import APIClient from "../../helpers/ApiClient";
import { useAuthContext } from "./AuthProvider";
import moment from "moment";
const SytContext = createContext();

export function useSytContext() {
  return useContext(SytContext);
}
let dateFormat = "YYYY-MM-DD";
function SytProvider({ children }) {
  const user=1;

  const [logo, setLogo] = useState("");
   const [blog, setBlog] = useState([]);
  const [visaGuideFaqs, setVisaGuideFaqs] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [countryTourList, setCountryTourList] = useState({});
  const [countryCruiseList, setCountryCruiseList] = useState({});
  const [popularVisaList, setPopularVisaList] = useState({});
  const [cruiseTypes, setCruisetypes] = useState({});
  const [popularCFFleets, setPopularCFFleets] = useState([]);
  const [popularActivityList, setpopularActivityList] = useState({});
  const [siteDetails, setSiteDetails] = useState({
    BusinessAddress: [],
    facebook: "https://www.facebook.com/",
    youtube: "https://www.youtube.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
  });
 
  const getSocialLinks = () => {
    let userID = 1;
    APIClient.get(`admin/settings/${userID}`)
      .then((res) => {
        if (res.status == 200) {
          const { settings } = res.data;

          let address = [];

          if (settings?.BusinessAddress?.length > 0) {
            address = settings?.BusinessAddress;
          }

          setSiteDetails((prev) => ({
            ...prev,
            BusinessAddress: address,
            facebook: settings.userSocialDetails.FacebookUrl,
            youtube: settings.userSocialDetails.Youtube,
            twitter: settings.userSocialDetails.Twitter,
            instagram: settings.userSocialDetails.Instagram,
          }));
        }
      })
      .catch((error) => {});
  };

  const [banners, setBanners] = useState({
    flightBanners: [],
    busBanners: [],
    hotelBanners: [],
    activityBanners: [],
    packBanners: [],
     flightSearchBanners: [],
    busSearchBanners: [],
    hotelSearchBanners: [],
    activitySearchBanners: [],
    packSearchBanners: [],
    footerBanners: [],
    bigHomeSliderBanners: [],
    smallHomeSliderBanners: [],
    carBanners: [],
    visaBanners: [],
    carSearchBanners: [],
    visaSearchBanners: [],
    holidayBanners: [],
    cruiseBanners: [],
    charterFlightBanners: [],
    charterFlightSearchBanners: [],
    holidayPackageSearchBanners: [],
    rechargeBillBanners: [],
    blogBanners: [],
    offerBanners: [],
  });

  const getBannersList = () => {
    APIClient.get("admin/banners")
      .then((res) => {
        if (res.status === 200) {
          let flightBanners = [];
          let busBanners = [];
          let hotelBanners = [];
          let activityBanners = [];
          let packBanners = [];
           let carBanners = [];
          let flightSearchBanners = [];
          let busSearchBanners = [];
          let hotelSearchBanners = [];
          let activitySearchBanners = [];
          let packSearchBanners = [];
          let footerBanners = [];
          let bigHomeSliderBanners = [];
          let smallHomeSliderBanners = [];
          let visaBanners = [];
          let carSearchBanners = [];
          let visaSearchBanners = [];
          let holidayBanners = [];
          let charterFlightBanners = [];
          let charterFlightSearchBanners = [];
          let holidayPackageSearchBanners = [];
          let cruiseBanners = [];
          let rechargeBillBanners = [];
          let offerBanners = [];
          let blogBanners = [];
          let logo = res.data.filter((item) => item.BannerType === 1);
          if (logo.length > 0 && logo[0].images.length > 0) {
            setLogo(logo[0].images[0].path);
          }
       

          let flightList = res.data.filter((item) => item.BannerType === 7);
          if (flightList.length > 0 && flightList[0].images.length > 0) {
            flightBanners = flightList[0].images;
          }

          let flightSearchList = res.data.filter(
            (item) => item.BannerType === 13
          );
          if (
            flightSearchList.length > 0 &&
            flightSearchList[0].images.length > 0
          ) {
            flightSearchBanners = flightSearchList[0].images;
          }

          let busList = res.data.filter((item) => item.BannerType === 8);
          if (busList.length > 0 && busList[0].images.length > 0) {
            busBanners = busList[0].images;
          }

          let busSearchList = res.data.filter((item) => item.BannerType === 14);
          if (busSearchList.length > 0 && busSearchList[0].images.length > 0) {
            busSearchBanners = busSearchList[0].images;
          }

          let carList = res.data.filter((item) => item.BannerType === 25);
          if (carList.length > 0 && carList[0].images.length > 0) {
            carBanners = carList[0].images;
          }

          let visaList = res.data.filter((item) => item.BannerType === 28);
          if (visaList.length > 0 && visaList[0].images.length > 0) {
            visaBanners = visaList[0].images;
          }
          let hotelList = res.data.filter((item) => item.BannerType === 9);
          if (hotelList.length > 0 && hotelList[0].images.length > 0) {
            hotelBanners = hotelList[0].images;
          }

          let hotelSearchList = res.data.filter(
            (item) => item.BannerType === 15
          );
          if (
            hotelSearchList.length > 0 &&
            hotelSearchList[0].images.length > 0
          ) {
            hotelSearchBanners = hotelSearchList[0].images;
          }

          let carSearchList = res.data.filter((item) => item.BannerType === 30);
          if (carSearchList.length > 0 && carSearchList[0].images.length > 0) {
            carSearchBanners = carSearchList[0].images;
          }
          let visaSearchList = res.data.filter(
            (item) => item.BannerType === 31
          );
          if (
            visaSearchList.length > 0 &&
            visaSearchList[0].images.length > 0
          ) {
            visaSearchBanners = visaSearchList[0].images;
          }

          let activityList = res.data.filter((item) => item.BannerType === 10);
          if (activityList.length > 0 && activityList[0].images.length > 0) {
            activityBanners = activityList[0].images;
          }
          let holidayList = res.data.filter((item) => item.BannerType === 31);
          if (holidayList.length > 0 && holidayList[0].images.length > 0) {
            holidayBanners = holidayList[0].images;
          }
          let cruiseList = res.data.filter((item) => item.BannerType === 46);
          if (cruiseList.length > 0 && cruiseList[0].images.length > 0) {
            cruiseBanners = cruiseList[0].images;
          }
          let rechargeList = res.data.filter((item) => item.BannerType === 47);
          if (rechargeList.length > 0 && rechargeList[0].images.length > 0) {
            rechargeBillBanners = rechargeList[0].images;
          }
          let blogList = res.data.filter((item) => item.BannerType === 48);
          if (blogList.length > 0 && blogList[0].images.length > 0) {
            blogBanners = blogList[0].images;
          }
          let offerList = res.data.filter((item) => item.BannerType === 49);
          if (offerList.length > 0 && offerList[0].images.length > 0) {
            offerBanners = offerList[0].images;
          }

          let charterFLightList = res.data.filter(
            (item) => item.BannerType === 40
          );
          if (
            charterFLightList.length > 0 &&
            charterFLightList[0].images.length > 0
          ) {
            charterFlightBanners = charterFLightList[0].images;
          }

          let charterFLightSearchList = res.data.filter(
            (item) => item.BannerType === 41
          );
          if (
            charterFLightSearchList.length > 0 &&
            charterFLightSearchList[0].images.length > 0
          ) {
            charterFlightSearchBanners = charterFLightSearchList[0].images;
          }

          let holidaySearchBanners = res.data.filter(
            (item) => item.BannerType === 42
          );
          if (
            holidaySearchBanners.length > 0 &&
            holidaySearchBanners[0].images.length > 0
          ) {
            holidayPackageSearchBanners = holidaySearchBanners[0].images;
          }

          let activitySearchList = res.data.filter(
            (item) => item.BannerType === 16
          );
          if (
            activitySearchList.length > 0 &&
            activitySearchList[0].images.length > 0
          ) {
            activitySearchBanners = activitySearchList[0].images;
          }

          let packageList = res.data.filter((item) => item.BannerType === 11);
          if (packageList.length > 0 && packageList[0].images.length > 0) {
            packBanners = packageList[0].images;
          }

          let packageSearchList = res.data.filter(
            (item) => item.BannerType === 17
          );
          if (
            packageSearchList.length > 0 &&
            packageSearchList[0].images.length > 0
          ) {
            packSearchBanners = packageSearchList[0].images;
          }

          let footerBannerList = res.data.filter(
            (item) => item.BannerType === 20
          );
          if (
            footerBannerList.length > 0 &&
            footerBannerList[0].images.length > 0
          ) {
            footerBanners = footerBannerList[0].images;
          }

          let bigHomeSliderList = res.data.filter(
            (item) => item.BannerType === 21
          );
          if (
            bigHomeSliderList.length > 0 &&
            bigHomeSliderList[0].images.length > 0
          ) {
            bigHomeSliderBanners = bigHomeSliderList[0].images;
          }
          let smallHomeSliderList = res.data.filter(
            (item) => item.BannerType === 22
          );
          if (
            smallHomeSliderList.length > 0 &&
            smallHomeSliderList[0].images.length > 0
          ) {
            smallHomeSliderBanners = smallHomeSliderList[0].images;
          }

          setBanners({
            flightBanners,
            busBanners,
            hotelBanners,
            activityBanners,
            packBanners,
             flightSearchBanners,
            busSearchBanners,
            hotelSearchBanners,
            activitySearchBanners,
            packSearchBanners,
            footerBanners,
            bigHomeSliderBanners,
            smallHomeSliderBanners,
            carBanners,
            visaBanners,
            carSearchBanners,
            visaSearchBanners,
            holidayBanners,
            charterFlightBanners,
            charterFlightSearchBanners,
            holidayPackageSearchBanners,
            cruiseBanners,
            rechargeBillBanners,
            blogBanners,
            offerBanners,
          });
         }
      })
      .catch(() => {});
  };

  const getBlogs = () => {
    APIClient.get("admin/blog")
      .then((res) => {
        if (res.statusCode == 200) {
          let data = res.data
            .filter((item) => item.BlogStatus === 1)
            .map((item) => ({
              ...item,
              urlTitle: item.BlogTitle ? item.BlogTitle.replace(/ /g, "") : "",
            }));

          setBlog(data);
        } else {
          setBlog([]);
        }
      })
      .catch((error) => {
        setBlog([]);
      });
  };

 
 
  const [promoDataSource, setPromoDataSource] = useState([]);

  const [topCities, setTopCities] = useState([]);

  // const getAllServicesTopCities = () => {
  //   setTopCities([]);
  //   APIClient.get("admin/getAllServicesTopCities").then((res) => {
  //     if (res.status == 200) {
  //       setTopCities(res.data);
  //     }
  //   });
  // };

  const getPromoCodes = () => {
    setPromoDataSource([]);
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let filteredData = res.data.filter(item => item.visibleFor !== null);
          let data = filteredData.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              ValidFrom: moment(item.ValidFrom).format(dateFormat),
              ValidTill: moment(item.ValidTill).format(dateFormat),
            };
          });
          // data = data.filter(item => item.visibleFor !== null);
          setPromoDataSource(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

 

 
  useEffect(() => {
    getBannersList();
    getSocialLinks();
    getBlogs();
   
    getPromoCodes();
    // getAllServicesTopCities();

 

   }, []);

 
  return (
    <SytContext.Provider
      value={{
        logo,
        blog,
        flightBanners: banners.flightBanners,
        busBanners: banners.busBanners,
        hotelBanners: banners.hotelBanners,
        activityBanners: banners.activityBanners,
        packBanners: banners.packBanners,
         flightSearchBanners: banners.flightSearchBanners,
        busSearchBanners: banners.busSearchBanners,
        hotelSearchBanners: banners.hotelSearchBanners,
        activitySearchBanners: banners.activitySearchBanners,
        packSearchBanners: banners.packSearchBanners,
        footerBanner: banners.footerBanners,
        bigHomeSliderBanners: banners.bigHomeSliderBanners,
        smallHomeSliderBanners: banners.smallHomeSliderBanners,
        getBannersList,
        siteDetails,
        carBanners: banners.carBanners,
        visaBanners: banners.visaBanners,
        carSearchBanners: banners.carSearchBanners,
        visaSearchBanners: banners.visaSearchBanners,
        holidayBanners: banners.holidayBanners,
        visaGuideFaqs,
        charterFlightBanners: banners.charterFlightBanners,
        charterFlightSearchBanners: banners.charterFlightSearchBanners,
        holidayPackageSearchBanners: banners.holidayPackageSearchBanners,
        promoDataSource: promoDataSource,
        topCities: topCities,
        cruiseBanners: banners.cruiseBanners,
        rechargeBillBanners: banners.rechargeBillBanners,
        offerBanners: banners.offerBanners,
        blogBanners: banners.blogBanners,
        popularCFFleets,
        cruiseTypes,
        popularVisaList,
        countryCruiseList,
        countryTourList,
        categoryList,
        popularActivityList,
      }}
    >
      {children}
    </SytContext.Provider>
  );
}
export default SytProvider;
