import React, { useState } from "react";
import EncHelper from "../../helpers/EncHelper";

export const AdminAccessContext = React.createContext();
const useStateWithEnc = (key) => {
  const [accessList, setAccessList] = useState(EncHelper.getItem(key) || null);

  return [accessList, setAccessList];
};

const useStateWithLocalStorage = (key) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(key) || null
  );

  return [accessToken, setAccessToken];
};

export function AdminAccessProvider({ children }) {
  const [accessList, setAccessList] = useStateWithEnc("access_screens");
  const [accessToken, setAccessToken] = useStateWithLocalStorage("accessToken");

  return (
    <AdminAccessContext.Provider
      value={{ accessList, setAccessList, accessToken, setAccessToken }}
    >
      {children}
    </AdminAccessContext.Provider>
  );
}
