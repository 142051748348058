import React, { useEffect, Suspense } from "react";
import { message, Spin } from "antd";
import { BrowserRouter as Router, useLocation, withRouter } from "react-router-dom";
import "./App.scss";

import { useAuthContext } from "./common/providers/AuthProvider";
import { AdminAccessProvider } from "./common/providers/AdminAccessProvider";

import GlobalStatesContext from "./common/providers/GlobalStatesContext";
//import CombinedNavbar from "./common/CombinedNavbar/CombinedNavbar";
//import Administration from "./admin/Administration";
//import UserNavigations from "./common/navbar/userNavigations";
//import CombinedFooter from "./common/CombinedFooter/CombinedFooter";
import HotelProvider from "./common/providers/Hotels/HotelProvider";
import SytProvider from "./common/providers/SytProvider";
import CurrencyContext from "./common/providers/CurrencyProvider";

const UserNavigations = React.lazy(() => import('./common/navbar/userNavigations'))
const CombinedNavbar = React.lazy(() => import('./common/CombinedNavbar/CombinedNavbar'))
const Administration = React.lazy(() => import('./admin/Administration'))
const CombinedFooter = React.lazy(() => import('./common/CombinedFooter/CombinedFooter'))

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const App = () => {
  const { tryLocalSignin } = useAuthContext();

  useEffect(() => {
    tryLocalSignin();

    message.config({
      duration: 3,
      maxCount: 1,
    });
  }, []);
  return (
    <GlobalStatesContext.Consumer>
      {(gscontext) => (
        <Router>
          <ScrollToTop>
            {gscontext.state.isLogin.role ? (
              <AdminAccessProvider>
                <Suspense fallback={<div className="loading-css-spin">
                <Spin />
                  Loading...</div>}>
                  <Administration updateIsLogin={gscontext.updateIsLogin} />
                </Suspense>
              </AdminAccessProvider>
            ) : (
              <div className="main_body">
                <CurrencyContext>
                  <SytProvider>
                    <HotelProvider>
                      <div className="main_content">
                        <Suspense fallback={<div className="loading-css-spin">
                          <Spin size="large" />
                          Loading...</div>}>
                          <CombinedNavbar gscontext={gscontext} />
                          <UserNavigations gscontext={gscontext} />
                        </Suspense>
                      </div>
                      <div className="footer_content">
                        <Suspense fallback={<div className="loading-css-spin">
                          <Spin  size="large"/>
                          Loading...</div>}>
                          <CombinedFooter />
                        </Suspense>
                      </div>
                    </HotelProvider>
                  </SytProvider>
                </CurrencyContext>
              </div>
            )}
          </ScrollToTop>
        </Router>
      )}
    </GlobalStatesContext.Consumer>
  );
};

export default App;
